import React, {Component} from 'react';
import axios from 'axios';

//const api_base = "http://api.btcoley.test/api";
const api_base = "https://app.btcoley.com/api";
const fist_state = {
    email: '',
    birthday: '',
    first_name: '',
    last_name: '',
    id_type: 'GOV_ID',
    id_number: '',
    phone: '',
    address: '',
    country: '',
    city: '',
    district: '',
    postcode: '',
    error: null,
    success: null,
};
class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = fist_state;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const data = this.state;
        const that = this;
        axios.post(api_base + '/decepticon/register', data)
            .then((response) => {
                if (response.data.status !== 200) {
                    that.setState({
                        error: response.data.error,
                    });
                } else {
                    this.setState(fist_state);
                    this.setState({
                        success: 'Üyelik işleminiz başarıyla gerçekleşti!',
                    });
                }
            })
            .catch((error) => {
                console.log(error.data)

            });
    }

    render() {
        const {error, success} = this.state;

        return (
            <div className="album py-5 bg-light">
                {error &&
                    <div className="alert alert-danger">
                        {Object.keys(error).map((key) => {
                            return (
                                <p key={key}>{error[key][0]}</p>
                            );
                        })}
                    </div>
                }
                {success &&
                    <div className="alert alert-success">
                        {success}
                    </div>
                }
                <div className="container">
                    <form onSubmit={this.handleSubmit}>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="first_name">İsim:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first_name"
                                        name="first_name"
                                        value={this.state.first_name}
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="last_name">Soyisim:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="last_name"
                                        name="last_name"
                                        value={this.state.last_name}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-group">
                                    <label htmlFor="birthday">Doğum Tarihi:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="birthday"
                                        name="birthday"
                                        value={this.state.birthday}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="id_number">Kimlik Numarası:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="id_number"
                                        name="id_number"
                                        value={this.state.id_number}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Telefon:</label>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        id="phone"
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Üye Ol</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default RegisterForm;

