import './App.css';
import RegisterForm from './registerForm';

function App() {
    return (
        <div>
            <RegisterForm/>
        </div>
    );
}

export default App;
